import { useForm, useWatch } from "react-hook-form";
import CiroBreadCrumbs from "../../components/shared/CiroBreadCrumbs";
import CiroContainer from "../../components/shared/CiroContainer";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { gql, useMutation, useQuery, useLazyQuery } from "@apollo/client";
import Loading from "../../components/shared/Loading";
import CiroAlert from "../../components/shared/CiroAlert";
import classNames from "classnames";
import CiroDropDown from "../../components/shared/CiroDropdown";
import CiroTextInput from "../../components/shared/CiroTextInput";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import CiroButton, {
  CiroButtonStyleEnum,
} from "../../components/shared/CiroButton";
import CiroModal from "../../components/shared/CiroModal";
import LinkSlashIcon from "../../assets/img/icons/LinkSlashIcon";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CiroCheckbox from "../../components/shared/CiroCheckbox";

export enum ContactObjectType {
  CONTACT = "CONTACT",
  LEAD = "LEAD",
}

const CrmIntegrationUpdateForm_UserAccount = gql`
  query CrmIntegrationUpdateForm_UserAccount {
    userAccount {
      org {
        id
        organizationMergeIntegration {
          id
          integration
          phone_number_status_field
          invalid_number_statuses
          should_read_valid_numbers
          valid_numbers_statuses
          org_contact_object_type
          overwrite
          timestamp_field
          default_phone_write_object
          default_phone_write_field
        }
      }
    }
  }
`;

const CrmMobileConnection_GetDispositions = gql`
  query CrmMobileConnection_GetDispositions($field: String) {
    organizationMergeIntegrationDispositions(field: $field)
  }
`;

const CrmMobileConnection_GetObjectFields = gql`
  query CrmMobileConnection_GetObjectFields($objectType: String!) {
    organizationMergeIntegrationObjectFields(objectType: $objectType) {
      success
      fields
    }
  }
`;

const CrmMobileConnection_UpdateOrganizationMergeIntegration = gql`
  mutation UpdateOrganizationMergeIntegration(
    $input: UpdateOrganizationMergeIntegrationInput!
  ) {
    updateOrganizationMergeIntegration(input: $input) {
      phone_number_status_field
      invalid_number_statuses
      valid_numbers_statuses
      overwrite
      timestamp_field
    }
  }
`;

const dispostionFieldOptions = [
  {
    label: "Standard Salesforce Disposition Field",
    value: "CallDisposition",
  },
  {
    label: "RingDna Disposition Field",
    value: "ringdna__Call_Disposition__c",
  },
  {
    label: "Aircall Disposition Field",
    value: "aircall__Connection_status__c",
  },
  { label: "Custom Field", value: "Custom" },
];
const overwriteOptions = [
  { label: "Always overwrite", value: "YES" },
  { label: "Overwrite confirmed bad numbers", value: "SAME_FIELD_ONLY" },
  { label: "Don't overwrite", value: "NO" },
];

const objectTypeOptions = [
  { label: "Lead", value: "LEAD" },
  { label: "Contact", value: "CONTACT" },
];

const CrmMobileConnection = () => {
  const CrmIntegrationUpdateFormSchema = yup.object({
    contact_object_type: yup.string().when("$integrationName", {
      is: "Salesforce",
      then: (schema) =>
        schema
          .required("Required")
          .test(
            "not-contact",
            "Contact object type is not supported",
            (value) => value !== ContactObjectType.CONTACT,
          ),
      otherwise: (schema) =>
        schema.oneOf(
          [ContactObjectType.CONTACT, ContactObjectType.LEAD],
          "Must be either CONTACT or LEAD",
        ),
    }),
    disposition_field: yup.string().when("$integrationName", {
      is: "Salesforce",
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    custom_disposition_field: yup
      .string()
      .when(["$integrationName", "disposition_field"], {
        is: (integrationName: string, dispositionField: string) =>
          integrationName === "Salesforce" && dispositionField === "Custom",
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    bad_number_dispositions: yup
      .array()
      .of(yup.string())
      .min(1, "At least one option is required"),
    good_number_dispositions: yup
      .array()
      .of(yup.string())
      .min(1, "At least one option is required"),
  });

  const [dispositionError, setDispositionError] = useState(false);
  const [integrationName, setIntegrationName] = useState("");

  const navigator = useNavigate();

  const { data, loading, error, refetch } = useQuery(
    CrmIntegrationUpdateForm_UserAccount,
    {
      onCompleted: (data) => {
        const fetchedDispositions =
          data?.organization?.organizationMergeIntegration?.dispostions || [];
        const parsedDispositions = fetchedDispositions.map(
          (dispositionString: string) => {
            const disposition = JSON.parse(dispositionString);
            return {
              label: disposition.label,
              value: disposition.id,
            };
          },
        );
        setDispositions(parsedDispositions);
        setDispositionError(
          fetchedDispositions.length === 0 && custom_disposition_field !== "",
        );
      },
    },
  );

  const CrmIntegrationOverrideFormSchema = yup.object({
    overwrite: yup
      .string()
      .oneOf(["YES", "SAME_FIELD_ONLY", "NO"], "Invalid overwrite option")
      .required("Overwrite option is required"),
    timestampField: yup.string().when("$timestamp", {
      is: true,
      then: (schema) => schema.required("Timestamp field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    defaultPhoneWriteField: yup.string().when("overwrite", {
      is: "YES",
      then: (schema) =>
        schema.required("Default phone write field is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const {
    control: overrideControl,
    formState: { errors: overrideErrors },
    trigger: triggerOverride,
    setError: setOverrideError,
    getValues: getOverrideValues,
    setValue: setOverrideValue,
    handleSubmit: handleOverrideSubmit,
    reset: resetOverride,
  } = useForm({
    resolver: yupResolver(CrmIntegrationOverrideFormSchema),
    defaultValues: {
      overwrite: "NO",
      timestampField: "",
      defaultPhoneWriteField: "",
    },
  });

  const [overwrite, timestampField, defaultPhoneWriteField] = useWatch({
    control: overrideControl,
    name: ["overwrite", "timestampField", "defaultPhoneWriteField"],
  });

  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(CrmIntegrationUpdateFormSchema),
    defaultValues: {
      contact_object_type:
        integrationName === "Salesforce"
          ? ContactObjectType.LEAD
          : ContactObjectType.CONTACT,
      disposition_field: null,
      custom_disposition_field: "",
      bad_number_dispositions: [],
      good_number_dispositions: [],
    },
  });

  const [isInitialSetup, setIsInitialSetup] = useState(true);
  const [timestamp, setTimestamp] = useState(false);

  const [getDispositions] = useLazyQuery(CrmMobileConnection_GetDispositions, {
    onCompleted: (data) => {
      const fetchedDispositions =
        data?.organizationMergeIntegrationDispositions || [];
      const parsedDispositions = fetchedDispositions.map(
        (dispositionString: string) => {
          const disposition = JSON.parse(dispositionString);
          return {
            label: disposition.label,
            value: disposition.id,
          };
        },
      );
      setDispositions(parsedDispositions);
      setDispositionError(fetchedDispositions.length === 0);
    },
  });

  const [getObjectFields, { loading: getObjectFieldsLoading }] = useLazyQuery(
    CrmMobileConnection_GetObjectFields,
    {
      onCompleted: (data) => {
        if (!data?.organizationMergeIntegrationObjectFields?.success) {
          setOverrideError("defaultPhoneWriteField", {
            type: "manual",
            message:
              "An error occurred while fetching object fields. Please try again.",
          });
          return;
        }
        const fetchedObjectFields =
          data?.organizationMergeIntegrationObjectFields?.fields || [];
        setObjectFields(
          fetchedObjectFields.map((field: any) => {
            const parsedField = JSON.parse(field);
            return {
              label: parsedField.fieldLabel,
              value: parsedField.fieldName,
            };
          }),
        );
      },
      onError: (error) => {
        console.error("Error fetching object fields:", error);
        setOverrideError("defaultPhoneWriteField", {
          type: "manual",
          message:
            "An error occurred while fetching object fields. Please try again.",
        });
      },
    },
  );

  useEffect(() => {
    if (data?.userAccount?.org?.organizationMergeIntegration) {
      const integration = data.userAccount.org.organizationMergeIntegration;
      setIntegrationName(integration.integration);
      setIsInitialSetup(integration.invalid_number_statuses === null);
      getDispositions({
        variables: { field: integration.phone_number_status_field },
      });
      getObjectFields({
        variables: {
          objectType:
            integrationName === "Salesforce"
              ? integration.org_contact_object_type
              : ContactObjectType.CONTACT,
        },
      });
      if (integration.timestamp_field) {
        setTimestamp(true);
      }
      reset({
        contact_object_type:
          integration.org_contact_object_type ||
          integrationName === "Salesforce"
            ? ContactObjectType.LEAD
            : ContactObjectType.CONTACT,
        disposition_field:
          dispostionFieldOptions
            .map((option) => option.value)
            .includes(integration.phone_number_status_field) || isInitialSetup
            ? integration.phone_number_status_field
            : "Custom",
        custom_disposition_field:
          dispostionFieldOptions
            .map((option) => option.value)
            .includes(integration.phone_number_status_field) || isInitialSetup
            ? ""
            : integration.phone_number_status_field,
        bad_number_dispositions: integration.invalid_number_statuses || [],
        good_number_dispositions: integration.valid_numbers_statuses || [],
      });
      resetOverride({
        overwrite: integration.overwrite,
        timestampField: integration.timestamp_field,
        defaultPhoneWriteField: integration.default_phone_write_field,
      });
    }
  }, [
    data,
    reset,
    getDispositions,
    isInitialSetup,
    resetOverride,
    getObjectFields,
    integrationName,
  ]);

  const [
    contact_object_type,
    disposition_field,
    custom_disposition_field,
    bad_number_dispositions,
    good_number_dispositions,
  ] = useWatch({
    control,
    name: [
      "contact_object_type",
      "disposition_field",
      "custom_disposition_field",
      "bad_number_dispositions",
      "good_number_dispositions",
    ],
  });

  const [updateOrganizationMergeIntegration, { loading: updateLoading }] =
    useMutation(CrmMobileConnection_UpdateOrganizationMergeIntegration);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetDispositions = useCallback(
    debounce((field: string) => {
      getDispositions({ variables: { field } });
    }, 1000),
    [getDispositions],
  );

  const [dispositions, setDispositions] = useState([]);
  const [objectFields, setObjectFields] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const handleRemoveIntegration = async () => {
    await updateOrganizationMergeIntegration({
      variables: {
        input: {
          org_contact_object_type: null,
          phone_number_status_field: null,
          invalid_number_statuses: null,
          valid_numbers_statuses: null,
          overwrite: "NO",
          timestamp_field: null,
          default_phone_write_field: null,
        },
      },
    });
    setShowRemoveModal(false);
    navigator("/mobile-numbers");
  };

  const onFinalizeSetup = async () => {
    try {
      const isMainFormValid = await trigger();
      const isOverrideFormValid = await triggerOverride();

      if (!isMainFormValid || !isOverrideFormValid) {
        return;
      }

      const mainFormData = getValues();
      const overrideFormData = getOverrideValues();

      const input = {
        org_contact_object_type:
          integrationName === "Salesforce"
            ? mainFormData.contact_object_type
            : ContactObjectType.CONTACT,
        invalid_number_statuses: mainFormData.bad_number_dispositions,
        valid_numbers_statuses: mainFormData.good_number_dispositions,
        phone_number_status_field:
          mainFormData.disposition_field === "Custom"
            ? mainFormData.custom_disposition_field
            : mainFormData.disposition_field,
        overwrite: overrideFormData.overwrite,
        timestamp_field:
          overrideFormData.overwrite !== "NO" && timestamp
            ? overrideFormData.timestampField
            : null,
        default_phone_write_field:
          overrideFormData.overwrite !== "NO"
            ? overrideFormData.defaultPhoneWriteField
            : null,
      };

      const result = await updateOrganizationMergeIntegration({
        variables: { input },
      });

      if (result.data) {
        refetch();
        setIsInitialSetup(false);
        toast.success("CRM connection setup successfully");
        navigator("/mobile-numbers");
      }
    } catch (error) {
      console.error("Error finalizing CRM connection setup:", error);
      toast.error(
        "An error occurred while setting up the connection. Please try again.",
      );
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await updateOrganizationMergeIntegration({
        variables: {
          input: {
            org_contact_object_type:
              integrationName === "Salesforce"
                ? data.contact_object_type
                : ContactObjectType.CONTACT,
            invalid_number_statuses: data.bad_number_dispositions,
            valid_numbers_statuses: data.good_number_dispositions,
            phone_number_status_field:
              data.disposition_field === "Custom"
                ? data.custom_disposition_field
                : data.disposition_field,
          },
        },
      });

      if (result.data) {
        refetch();
        toast.success("Changes saved successfully");
      }
    } catch (error) {
      console.error("Error updating organization merge integration:", error);
      alert("An error occurred while saving changes. Please try again.");
    }
  });

  const onOverrideSubmit = handleOverrideSubmit(async (data) => {
    try {
      await updateOrganizationMergeIntegration({
        variables: {
          input: {
            overwrite: data.overwrite ? "YES" : "NO",
            timestamp_field: data.timestampField,
            default_phone_write_field: data.defaultPhoneWriteField,
          },
        },
      });
      toast.success("Override settings saved successfully");
    } catch (error) {
      console.error("Error updating override settings:", error);
      toast.error(
        "An error occurred while saving override settings. Please try again.",
      );
    }
  });

  return (
    <CiroContainer className={classNames("ciro-v1-bg-zinc-100")}>
      <div className={classNames("ciro-v1-w-full")}>
        <CiroBreadCrumbs
          values={["Enrich Data", "Mobile Numbers", "CRM Connection"]}
          href="/mobile-numbers"
        />
        {loading && <Loading />}
        {error && <CiroAlert message={error.message} />}
        <div
          className={classNames(
            "ciro-v1-flex",
            "ciro-v1-justify-between",
            "ciro-v1-items-center",
          )}
        >
          {isInitialSetup ? (
            <h1 className={classNames("ciro-v1-text-2xl", "ciro-v1-pb-6")}>
              Setup Your {integrationName} Connection
            </h1>
          ) : (
            <h1 className={classNames("ciro-v1-text-2xl", "ciro-v1-pb-6")}>
              Manage {integrationName} Connection
            </h1>
          )}
          <div className={classNames("ciro-v1-flex", "ciro-v1-gap-4")}>
            {!isInitialSetup && (
              <CiroButton
                analyticsField="remove_salesforce_connection"
                onClick={() => setShowRemoveModal(true)}
              >
                <LinkSlashIcon />
                <span className={classNames("ciro-v1-ml-2")}>
                  Remove {integrationName} Connection
                </span>
              </CiroButton>
            )}
            {isInitialSetup && (
              <CiroButton
                analyticsField="finalize_crm_integration_setup"
                onClick={onFinalizeSetup}
                disabled={updateLoading}
                style={CiroButtonStyleEnum.LOUD}
              >
                Finalize Setup
              </CiroButton>
            )}
          </div>
        </div>
        {data && (
          <div>
            <div className={classNames("ciro-v1-flex", "ciro-v1-pb-4")}>
              <div className={classNames("ciro-v1-w-1/2")}>
                <h1
                  className={classNames(
                    "ciro-v1-text-neutral-900",
                    "ciro-v1-text-base",
                    "ciro-v1-font-bold",
                    "ciro-v1-mb-2",
                  )}
                >
                  {integrationName} Connection Settings
                </h1>
                <h2
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                    "ciro-v1-font-normal",
                  )}
                >
                  Define the criteria for determining call success and number
                  validity in your {integrationName} integration.
                </h2>
              </div>
              <div
                className={classNames(
                  "ciro-v1-bg-white",
                  "ciro-v1-p-4",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-border",
                  "ciro-v1-border-neutral-200",
                  "ciro-v1-w-full",
                )}
              >
                <div>
                  {integrationName === "Salesforce" && (
                    <>
                      <div>
                        <CiroDropDown
                          creatable={false}
                          async={false}
                          label="Which object type do you want to connect to?"
                          options={objectTypeOptions}
                          value={contact_object_type}
                          isOptionDisabled={(option) =>
                            option.value === ContactObjectType.CONTACT
                          }
                          onChange={(v) => {
                            setValue("contact_object_type", v as any, {
                              shouldValidate: true,
                            });
                          }}
                          error={errors.contact_object_type?.message as string}
                        />
                      </div>
                      <div className={classNames("ciro-v1-pt-4")}>
                        <CiroDropDown
                          creatable={false}
                          async={false}
                          isDisabled={contact_object_type === null}
                          label="Which field contains data on whether the call was successful e.g., “CallDisposition”"
                          options={dispostionFieldOptions}
                          value={disposition_field}
                          onChange={async (v) => {
                            setValue("disposition_field", v as any);
                            setValue("custom_disposition_field", "");
                            setValue("bad_number_dispositions", []);
                            setValue("good_number_dispositions", []);
                            setDispositions([]);
                            if (v !== "Custom") {
                              getDispositions({ variables: { field: v } });
                            }
                          }}
                          error={
                            dispositionError && disposition_field !== "Custom"
                              ? "No dispostions found for selected field"
                              : (errors.disposition_field?.message as string)
                          }
                        />
                      </div>
                      {disposition_field === "Custom" && (
                        <div
                          className={classNames(
                            "ciro-v1-pt-4",
                            "ciro-v1-relative",
                          )}
                        >
                          <CiroTextInput
                            label="Custom Disposition Field"
                            value={custom_disposition_field}
                            error={
                              dispositionError
                                ? "No dispositions found for this field"
                                : errors.custom_disposition_field?.message
                            }
                            onChange={(v) => {
                              setValue(
                                "custom_disposition_field",
                                v.target.value,
                              );
                              debouncedGetDispositions(v.target.value);
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className={classNames("ciro-v1-pt-4")}>
                    <CiroDropDown
                      isDisabled={dispositions.length === 0}
                      isMulti={true}
                      label="Which value(s) indicate whether the contact needs a new number, e.g. “Wrong Number”"
                      options={dispositions}
                      value={bad_number_dispositions}
                      onChange={(v) => {
                        setValue("bad_number_dispositions", v as any);
                      }}
                      error={errors.bad_number_dispositions?.message as string}
                    />
                  </div>
                  <div className={classNames("ciro-v1-pt-4")}>
                    <CiroDropDown
                      isDisabled={dispositions.length === 0}
                      isMulti={true}
                      label="Which value(s) for the field indicate whether the number was succesfully reached, e.g. “Answered”"
                      options={dispositions}
                      value={good_number_dispositions}
                      onChange={(v) => {
                        setValue("good_number_dispositions", v as any);
                      }}
                      error={errors.good_number_dispositions?.message as string}
                    />
                  </div>
                </div>

                {!isInitialSetup && (
                  <div
                    className={classNames(
                      "ciro-v1-pt-6",
                      "ciro-v1-flex",
                      "ciro-v1-justify-end",
                    )}
                  >
                    <CiroButton
                      analyticsField="save_crm_integration_changes"
                      onClick={onSubmit}
                      disabled={updateLoading}
                      style={CiroButtonStyleEnum.LOUD}
                    >
                      Save Changes
                    </CiroButton>
                  </div>
                )}
              </div>
            </div>
            <div className={classNames("ciro-v1-flex", "ciro-v1-gap-4")}>
              <div>
                <h1
                  className={classNames(
                    "ciro-v1-text-neutral-900",
                    "ciro-v1-text-base",
                    "ciro-v1-font-bold",
                    "ciro-v1-mb-2",
                  )}
                >
                  Override Settings in {integrationName}
                </h1>
                <h2
                  className={classNames(
                    "ciro-v1-text-neutral-500",
                    "ciro-v1-text-sm",
                    "ciro-v1-font-normal",
                  )}
                >
                  Configure how Ciro integrates with and updates contact
                  information in {integrationName}.
                </h2>
              </div>
              <div
                className={classNames(
                  "ciro-v1-bg-white",
                  "ciro-v1-p-4",
                  "ciro-v1-rounded-lg",
                  "ciro-v1-border",
                  "ciro-v1-border-neutral-200",
                  "ciro-v1-w-full",
                )}
              >
                <div className={classNames("ciro-v1-pt-4")}>
                  <CiroDropDown
                    label="Should we overwrite the number in the crm?"
                    options={overwriteOptions}
                    value={overwrite}
                    onChange={(v) => {
                      setOverrideValue("overwrite", v as any);
                    }}
                  />
                </div>
                {overwrite === "YES" && (
                  <div className={classNames("ciro-v1-pt-4")}>
                    <CiroDropDown
                      label="What is the default phone number field?"
                      isLoading={getObjectFieldsLoading || loading}
                      options={objectFields}
                      value={defaultPhoneWriteField}
                      onChange={(v) => {
                        setOverrideValue("defaultPhoneWriteField", v);
                      }}
                      error={
                        overrideErrors.defaultPhoneWriteField?.message as string
                      }
                    />
                  </div>
                )}
                {(overwrite === "YES" || overwrite === "SAME_FIELD_ONLY") && (
                  <div className={classNames("ciro-v1-pt-4")}>
                    <CiroCheckbox
                      label="Create timestamp field to indicate when the contact was updated by Ciro with a new number"
                      checked={timestamp}
                      onClick={() => {
                        setTimestamp(!timestamp);
                        if (!timestamp) {
                          setOverrideValue("timestampField", "");
                        }
                      }}
                    />
                  </div>
                )}
                {timestamp && (
                  <div className={classNames("ciro-v1-pt-4")}>
                    <CiroTextInput
                      label="Enter a name for the timestamp field"
                      value={timestampField}
                      onChange={(v) => {
                        setOverrideValue("timestampField", v.target.value);
                      }}
                      error={overrideErrors.timestampField?.message as string}
                    />
                  </div>
                )}
                {!isInitialSetup && (
                  <div
                    className={classNames(
                      "ciro-v1-pt-4",
                      "ciro-v1-flex",
                      "ciro-v1-justify-end",
                    )}
                  >
                    <CiroButton
                      analyticsField="save_override_settings"
                      onClick={onOverrideSubmit}
                      disabled={updateLoading}
                      style={CiroButtonStyleEnum.LOUD}
                    >
                      Save Changes
                    </CiroButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <CiroModal
        isOpen={showRemoveModal}
        onClose={() => setShowRemoveModal(false)}
      >
        <div className="ciro-v1-mb-6">
          <h2 className="ciro-v1-text-xl ciro-v1-font-semibold ciro-v1-mb-2">
            Remove {integrationName} Connection
          </h2>
          <p className="ciro-v1-mb-2">
            Are you sure you want to remove the {integrationName} connection?
          </p>
          <p className="ciro-v1-text-red-600">This action cannot be undone.</p>
        </div>
        <div className="ciro-v1-flex ciro-v1-justify-end ciro-v1-gap-4">
          <CiroButton
            analyticsField="cancel_remove_salesforce_connection"
            onClick={() => setShowRemoveModal(false)}
          >
            Cancel
          </CiroButton>
          <CiroButton
            analyticsField="remove_salesforce_connection"
            onClick={handleRemoveIntegration}
            style={CiroButtonStyleEnum.DELETE}
          >
            Remove
          </CiroButton>
        </div>
      </CiroModal>
    </CiroContainer>
  );
};

export default CrmMobileConnection;
