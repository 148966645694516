import classNames from "classnames";
import CiroTable from "../../shared/CiroTable/CiroTable";
import CiroTableHeader, {
  CiroTableHeaderAlignEnum,
} from "../../shared/CiroTable/CiroTableHeader";
import CiroTableRow from "../../shared/CiroTable/CiroTableRow";
import { gql } from "@apollo/client";
import {
  MobileNumbersChromeExtensionRow_QueryFragmentDoc,
  MobileNumbersTable_QueryFragment,
  PhoneNumbersRequestTransactionStatusEnum,
} from "../../../__generated__/graphql";
import CiroTableCell, {
  CiroTableCellAlignEnum,
} from "../../shared/CiroTable/CiroTableCell";
import { format } from "date-fns";
import CiroPill from "../../shared/CiroPill";
import DotIcon from "../../../assets/img/icons/DotIcon";
import MobileNumbersDownloadButton from "../MobileNumbersDownloadButton";
import MobileNumbersChromeExtensionRow, {
  MobileNumbersChromeExtensionRow_Query,
} from "./MobileNumbersChromeExtensionRow";
import { useFragment } from "../../../__generated__";

export const MobileNumbersTable_Query = gql`
  fragment MobileNumbersTable_Query on Query {
    userAccount {
      organization_ciro_id
    }
    batchTransactions: phoneNumbersRequestTransactions(requestType: batch) {
      id
      created_at
      requestedContactsCount
      newNumbersCount
      numbersCalledCount
      conversationsCreatedCount
      status
      creditsUsed
    }
    ...MobileNumbersChromeExtensionRow_Query
  }
  ${MobileNumbersChromeExtensionRow_Query}
`;

const statusOptions = {
  [PhoneNumbersRequestTransactionStatusEnum.InProgress]: {
    name: "In Progress",
    fontColor: "ciro-v1-text-amber-800",
    background: "ciro-v1-bg-amber-100",
    dotColor: "ciro-v1-text-amber-600",
  },
  [PhoneNumbersRequestTransactionStatusEnum.Completed]: {
    name: "Completed",
    fontColor: "ciro-v1-text-green-800",
    background: "ciro-v1-bg-green-100",
    dotColor: "ciro-v1-text-green-600",
  },
  [PhoneNumbersRequestTransactionStatusEnum.Failed]: {
    name: "Failed",
    fontColor: "ciro-v1-text-red-800",
    background: "ciro-v1-bg-red-100",
    dotColor: "ciro-v1-text-red-600",
  },
};

const MobileNumbersTable = ({
  data,
}: {
  data: MobileNumbersTable_QueryFragment;
}) => {
  const headers = [
    <CiroTableHeader key="start-time-of-run" isFirst={true}>
      Start time of run
    </CiroTableHeader>,
    <CiroTableHeader
      key="requested-contacts"
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      Requested Contacts
    </CiroTableHeader>,
    <CiroTableHeader key="new-numbers" align={CiroTableHeaderAlignEnum.RIGHT}>
      New Numbers
    </CiroTableHeader>,
    <CiroTableHeader
      key="numbers-called"
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      Numbers Called
    </CiroTableHeader>,
    <CiroTableHeader
      key="conversations-created"
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      Conversations Created
    </CiroTableHeader>,
    <CiroTableHeader key="status">Status</CiroTableHeader>,
    <CiroTableHeader key="credits-used">Credits Used</CiroTableHeader>,
    <CiroTableHeader
      key="download"
      isLast={true}
      align={CiroTableHeaderAlignEnum.RIGHT}
    >
      &nbsp;
    </CiroTableHeader>,
  ];

  const chromeExtensionData = useFragment(
    MobileNumbersChromeExtensionRow_QueryFragmentDoc,
    data,
  );

  return (
    <CiroTable>
      <thead className={classNames("ciro-v1-table-header-group")}>
        <CiroTableRow>{headers}</CiroTableRow>
      </thead>
      <tbody className={classNames("ciro-v1-table-row-group")}>
        <MobileNumbersChromeExtensionRow data={chromeExtensionData} />
        {data.batchTransactions.map((phoneNumberRequestTransaction) => {
          const statusOption =
            statusOptions[phoneNumberRequestTransaction.status];

          return (
            <CiroTableRow
              key={phoneNumberRequestTransaction.id}
              clickable={false}
            >
              <CiroTableCell>
                {format(
                  new Date(phoneNumberRequestTransaction.created_at),
                  "yyyy-MM-dd HH:mm",
                )}
              </CiroTableCell>
              <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                {phoneNumberRequestTransaction.requestedContactsCount}
              </CiroTableCell>
              <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                {phoneNumberRequestTransaction.newNumbersCount}
              </CiroTableCell>
              <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                {phoneNumberRequestTransaction.numbersCalledCount}
              </CiroTableCell>
              <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                {phoneNumberRequestTransaction.conversationsCreatedCount}
              </CiroTableCell>
              <CiroTableCell>
                <CiroPill
                  fontColor={statusOption.fontColor}
                  background={statusOption.background}
                >
                  <div
                    className={classNames(
                      "ciro-v1-flex",
                      "ciro-v1-items-center",
                      "ciro-v1-gap-1",
                    )}
                  >
                    <span className={classNames(statusOption.dotColor)}>
                      <DotIcon />
                    </span>
                    {statusOption.name}
                  </div>
                </CiroPill>
              </CiroTableCell>
              <CiroTableCell>
                {phoneNumberRequestTransaction.creditsUsed}
              </CiroTableCell>
              <CiroTableCell align={CiroTableCellAlignEnum.RIGHT}>
                <MobileNumbersDownloadButton
                  organizationCiroId={data.userAccount?.organization_ciro_id!}
                  phoneNumbersRequestTransactionId={
                    phoneNumberRequestTransaction.id
                  }
                />
              </CiroTableCell>
            </CiroTableRow>
          );
        })}
      </tbody>
    </CiroTable>
  );
};

export default MobileNumbersTable;
