import { useEffect } from "react";
import LargeScreenNavigation from "./LargeScreenNavigation";
import SmallScreenNavigation from "./SmallScreenNavigation";
import classNames from "classnames";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import {
  NavigationContainer_PermissionsQuery,
  NavigationContainer_PermissionsQueryVariables,
  Navigation_StopImpersonatingMutation,
  Navigation_StopImpersonatingMutationVariables,
} from "../../__generated__/graphql";
import { FULL_SCREEN_CONTAINER_CLASSES } from "../shared/CiroContainer";
import NavigationContext from "../../contexts/NavigationContext";
import { usePostHog } from "posthog-js/react";

interface INavigationContainerProps {
  children: any;
}

export interface ISharedNavigationProps {
  loading: boolean;
  navData?: NavigationContainer_PermissionsQuery;
  stopImpersonating: () => void;
}

// Fragments ignored to ensure data used in both containers is identical,
// one navbar is not changed without accounting for the other.
const NavigationContainer_Permissions = gql`
  query NavigationContainer_Permissions {
    permissions {
      hasAdminPermissions
      canViewCiroNumbers
      canViewEnrichments
      canViewUploadCsv
    }
    userAccount {
      id
      impersonating {
        email
      }
      org {
        credit_plan {
          balance
        }
        subscription_tier
      }
    }
  }
`;

const Navigation_StopImpersonating = gql`
  mutation Navigation_StopImpersonating {
    stopImpersonating {
      success
    }
  }
`;

const NavigationContainer = ({ children }: INavigationContainerProps) => {
  const { isAuthenticated } = useAuth0();
  const posthog = usePostHog();
  const { user } = useAuth0();

  const {
    data,
    loading,
    refetch: refreshNavData,
  } = useQuery<
    NavigationContainer_PermissionsQuery,
    NavigationContainer_PermissionsQueryVariables
  >(NavigationContainer_Permissions, {
    skip: !isAuthenticated,
  });

  const [stopImpersonating, { data: stopImpersonatingData }] = useMutation<
    Navigation_StopImpersonatingMutation,
    Navigation_StopImpersonatingMutationVariables
  >(Navigation_StopImpersonating);

  useEffect(() => {
    if (
      stopImpersonatingData &&
      stopImpersonatingData.stopImpersonating.success
    ) {
      window.location.reload();
    }
    if (data) {
      posthog.identify(user?.sub, {
        subscriptionTier: data?.userAccount?.org?.subscription_tier,
      });
    }
  });

  return (
    <NavigationContext.Provider value={{ refreshNavData }}>
      <div>
        <SmallScreenNavigation
          stopImpersonating={stopImpersonating}
          loading={loading}
          navData={data}
        />
        <div className={classNames("ciro-v1-flex", "ciro-v1-relative")}>
          <LargeScreenNavigation
            stopImpersonating={stopImpersonating}
            loading={loading}
            navData={data}
          />
          <div
            className={classNames(
              "ciro-v1-text-center",
              "ciro-v1-w-screen",
              FULL_SCREEN_CONTAINER_CLASSES,
              {
                "lg:ciro-v1-ml-72": isAuthenticated,
                "lg:ciro-v1-w-screen-minus-72": isAuthenticated,
              },
            )}
          >
            <div className={classNames("ciro-v1-min-h-full")}>{children}</div>
          </div>
        </div>
      </div>
    </NavigationContext.Provider>
  );
};

export default NavigationContainer;
